import React from "react";
import SEO from "../components/seo";
import "../css/servicos.css";

import servicoApostilamento from "../images/servico-apostilamento.png";
import servicoCasamento from "../images/servico-casamento.png";
import servicoCertidoes from "../images/servico-certidoes.png";
import servicoMensalista from "../images/servico-mensalista.png";
import servicoProcedimentosAverbacoes from "../images/servico-procedimentos-averbacoes.png";
import servicoProcuracao from "../images/servico-procuracao.png";
import servicoRegistroNascimento from "../images/servico-registro-nascimento.png";
import servicoRegistroObito from "../images/servico-registro-obito.png";
import { Link } from "gatsby";

function ServicosPage(props) {
    return (
        <>
            <SEO title="Serviços" />
            {!props["*"] && (
                <section className="py-8 px-12 lg:py-16 lg:px-6">
                    <div className="container mx-auto  text-azul_base ">
                        <h1 className="leading-none text-2xl font-bold mb-4 lg:text-4xl">Serviços de Firma e Balcão</h1>
                        <div className="mt-6 mb-20">
                            <ul className="grid items-center grid-cols-1 lg:grid-cols-3 text-base lg:text-xl grid-flow-row col-gap-5 row-gap-5">
                                <li className="cursor-pointer rounded-lg servicosCardItem">
                                    <Link className="flex py-5 px-2" to="/servicos/abertura-de-firmas">
                                        Aberturas de Firmas
                                    </Link>
                                </li>
                                <li className="cursor-pointer rounded-lg servicosCardItem">
                                    <Link className="flex py-5 px-2" to="/servicos/reconhecimento-de-firmas">
                                        Reconhecimento de Firmas
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <h2 className="leading-none text-2xl font-bold mb-4 lg:text-4xl">Serviços Específicos</h2>
                        <div className="mb-32 mt-6">
                            <ul className="grid grid-cols-2 lg:grid-cols-3 text-lg grid-flow-row col-gap-5 row-gap-5">
                                <li className="cursor-pointer px-2 rounded-lg servicosCardItem">
                                    <Link to="/servicos/certidoes/">
                                        <img
                                            className="w-40 -mt-2 lg:-mt-4"
                                            src={servicoCertidoes}
                                            alt="Serviço Certidões"
                                        />
                                    </Link>
                                </li>
                                <li className="cursor-pointer px-2 rounded-lg servicosCardItem">
                                    <Link to="/servicos/registro-de-nascimento/">
                                        <img
                                            className="w-48"
                                            src={servicoRegistroNascimento}
                                            alt="Serviço Registro de nascimento"
                                        />
                                    </Link>
                                </li>
                                <li className="cursor-pointer px-2 rounded-lg servicosCardItem">
                                    <Link to="/servicos/casamentos/">
                                        <img className="w-48" src={servicoCasamento} alt="Serviço Casamentos" />
                                    </Link>
                                </li>
                                <li className="cursor-pointer px-2 rounded-lg servicosCardItem">
                                    <Link to="/servicos/registro-de-obito/">
                                        <img
                                            className="w-40"
                                            src={servicoRegistroObito}
                                            alt="Serviço Registro de óbito"
                                        />
                                    </Link>
                                </li>
                                <li className="cursor-pointer px-2 rounded-lg servicosCardItem">
                                    <Link to="/servicos/procedimentos-e-averbacoes/">
                                        <img
                                            className="w-48"
                                            src={servicoProcedimentosAverbacoes}
                                            alt="Serviço Procedimentos e Averbações"
                                        />
                                    </Link>
                                </li>
                                <li className="cursor-pointer px-2 rounded-lg servicosCardItem">
                                    <Link to="/servicos/procuração/">
                                        <img className="w-48" src={servicoProcuracao} alt="Serviço Procuração" />
                                    </Link>
                                </li>
                                <li className="cursor-pointer px-2 rounded-lg servicosCardItem">
                                    <Link to="/servicos/apostilamento/">
                                        <img className="w-48" src={servicoApostilamento} alt="Serviço Apostilamento" />
                                    </Link>
                                </li>
                                <li className="cursor-pointer px-2 rounded-lg servicosCardItem">
                                    <Link to="/servicos/mensalistas/">
                                        <img className="w-48" src={servicoMensalista} alt="Serviço Mensalistas" />
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
            )}
            {props["*"] && (
                <div className="container mx-auto py-6 px-5 lg:px-0">
                    <div className="mb-2">
                        <button
                            onClick={() => window.history.back()}
                            className="text-azul_claro hover:underline cursor-pointer font-bold text-lg w-full text-left lg:w-auto">
                            &lt; voltar
                        </button>
                    </div>
                    <div className="flex pb-24">
                        <aside className="hidden lg:block w-3/12 mr-4">
                            <nav className="shadow-lg px-4 py-5 servicosCardItem mb-4 rounded-lg">
                                <ul>
                                    <p className="font-bold text-xl text-azul_claro">Serviços</p>
                                    <li className="text-cinza_escuro hover:underline cursor-pointer mb-1">
                                        Aberturas de Firmas
                                    </li>
                                    <li className="text-cinza_escuro hover:underline cursor-pointer mb-1">
                                        Reconhecimento de Firmas
                                    </li>
                                    <li className="text-cinza_escuro hover:underline cursor-pointer mb-4">
                                        Autenticação de Documento
                                    </li>
                                    <p className="font-bold text-xl text-azul_claro">Serviços Especiais</p>
                                    <li className="text-cinza_escuro hover:underline cursor-pointer mb-1">
                                        Procedimentos e averbações
                                    </li>
                                    <li className="text-cinza_escuro hover:underline cursor-pointer mb-1">
                                        Apostilamento
                                    </li>
                                    <li className="text-cinza_escuro hover:underline cursor-pointer mb-1">
                                        Mensalista
                                    </li>
                                    <li className="text-cinza_escuro hover:underline cursor-pointer mb-1">
                                        Registro de nascimento
                                    </li>
                                    <li className="text-cinza_escuro hover:underline cursor-pointer mb-1">Casamento</li>
                                    <li className="text-cinza_escuro hover:underline cursor-pointer mb-1">
                                        Registro de Óbito
                                    </li>
                                    <li className="text-cinza_escuro hover:underline cursor-pointer mb-1">
                                        Procuração
                                    </li>
                                    <li className="text-cinza_escuro hover:underline cursor-pointer">Certidões</li>
                                </ul>
                            </nav>
                            <p className="text-azul_claro text-lg">
                                {" "}
                                Para mais informações sobre este serviço entrar em contato através do e-mail exemplo@
                            </p>
                        </aside>
                        <div className="lg:w-7/12">
                            <article className="mb-5">
                                <h1 className="text-3xl text-azul_claro mb-4">Aberturas de Firmas</h1>
                                <p className="text-lg text-azul_claro mb-1 font-bold">O que é?</p>
                                <p className="text-vermelho mb-8">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed placerat sapien vitae
                                    magna finibus, non faucibus ipsum ornare. Mauris et pharetra ante. Donec ac diam
                                    vitae nunc aliquet dictum. Quisque facilisis turpis vitae neque sollicitudin
                                    vehicula. Curabitur lobortis erat et vehicula interaugue et vestibulum ultrices.
                                </p>
                                <p className="text-lg text-azul_claro mb-1 font-bold">Como funciona?</p>
                                <p className="text-vermelho mb-8">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed placerat sapien vitae
                                    magna finibus, non faucibus ipsum ornare. Mauris et pharetra ante. Donec ac diam
                                    vitae nunc aliquet dictum. Quisque facilisis turpis vitae neque sollicitudin
                                    vehicula. Curabitur lobortis erat et vehicula interaugue et vestibulum ultrices.
                                </p>
                                <p className="text-lg text-azul_claro mb-1 font-bold">Pergunta?</p>
                                <p className="text-vermelho mb-8">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed placerat sapien vitae
                                    magna finibus, non faucibus ipsum ornare. Mauris et pharetra ante. Donec ac diam
                                    vitae nunc aliquet dictum. Quisque facilisis turpis vitae neque sollicitudin
                                    vehicula. Curabitur lobortis erat et vehicula interaugue et vestibulum ultrices.
                                </p>
                                <p className="text-lg text-azul_claro mb-1 font-bold">Pergunta?</p>
                                <p className="text-vermelho mb-8">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed placerat sapien vitae
                                    magna finibus, non faucibus ipsum ornare. Mauris et pharetra ante. Donec ac diam
                                    vitae nunc aliquet dictum. Quisque facilisis turpis vitae neque sollicitudin
                                    vehicula. Curabitur lobortis erat et vehicula interaugue et vestibulum ultrices.
                                </p>
                                <p className="text-lg text-azul_claro mb-1 font-bold">Pergunta?</p>
                                <p className="text-vermelho">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed placerat sapien vitae
                                    magna finibus, non faucibus ipsum ornare. Mauris et pharetra ante. Donec ac diam
                                    vitae nunc aliquet dictum. Quisque facilisis turpis vitae neque sollicitudin
                                    vehicula. Curabitur lobortis erat et vehicula interaugue et vestibulum ultrices.
                                </p>
                            </article>
                            <p className="text-xl text-azul_claro mb-2 font-bold">
                                Consulte em nossos registros a existencia de firmas reconhecidas
                            </p>
                            <label className="block text-azul_base text-lg" htmlFor="cpf">
                                CPF
                            </label>
                            <div className="lg:flex mb-12">
                                <input className="rounded-lg bg-cinza_claro p-2 w-full lg:mr-5" id="cpf" type="text" />
                                <button className="rounded bg-azul_claro text-white px-5 py-2 mt-4 lg:mt-0">
                                    Enviar
                                </button>
                            </div>
                            <p className="text-xl text-azul_claro mb-2 font-bold">
                                Preencha o formulário abaixo para dar entrada no seu processo
                            </p>
                            <div className="mb-12">
                                <button className="rounded bg-azul_claro text-white px-5 py-2">
                                    Preencher formulário
                                </button>
                            </div>
                            <p className="text-2xl text-azul_claro mb-2 font-bold">
                                Evite filas! Agende um horário e tenha preferência para a realização da abertura de
                                firmas no cartório.
                            </p>
                            <p className="text-lg text-cinza_default mb-4">
                                Os atendimentos que não forem agendados serão realizados normalmente por ordem de
                                chegada.
                            </p>
                            <div className="grid gap-5 lg:grid-cols-2 grid-flow-row lg:w-10/12">
                                <div>
                                    <label className="block text-azul_base text-lg" htmlFor="nome">
                                        Nome
                                    </label>
                                    <input
                                        className="rounded-lg bg-cinza_claro p-2 mt-2 w-full"
                                        id="nome"
                                        type="text"
                                    />
                                </div>
                                <div>
                                    <label className="block text-azul_base text-lg" htmlFor="email">
                                        E-mail
                                    </label>
                                    <input
                                        className="rounded-lg bg-cinza_claro p-2 mt-2 w-full"
                                        id="email"
                                        type="text"
                                    />
                                </div>
                                <div>
                                    <label className="block text-azul_base text-lg" htmlFor="availableDays">
                                        Dias disponíveis
                                    </label>
                                    <input
                                        className="rounded-lg bg-cinza_claro p-2 mt-2 w-full"
                                        id="availableDays"
                                        type="text"
                                    />
                                </div>
                                <div>
                                    <label className="block text-azul_base text-lg" htmlFor="availableTimes">
                                        Horários
                                    </label>
                                    <input
                                        className="rounded-lg bg-cinza_claro p-2 mt-2 w-full"
                                        id="availableTimes"
                                        type="text"
                                    />
                                </div>
                            </div>
                            <div className="mt-5 text-lg">
                                <button className="rounded bg-azul_claro text-white px-5 py-2">Enviar</button>
                            </div>
                            <p className="text-azul_escuro mt-5 lg:hidden font-bold">
                                Para mais informações sobre este serviço entrar em contato através do e-mail exemplo@
                            </p>
                            <div className="mt-8">
                                <iframe
                                    title="Região de cobertura do cartório"
                                    height="400px"
                                    className="w-full"
                                    src="https://www.google.com/maps/d/u/0/embed?mid=1r_6gIil_KqBBNucl_aWK6KLIHS6icgRi"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default ServicosPage;
